<template>
    <div style="display: flex">
        <div>
            <!-- <Menu :active-name="act" :open-names="openList" :theme="theme" @on-open-change="changeTest" @on-select="updateList">
                <Submenu name="1">
                    <template #title>
                        <Icon type="ios-paper" />
                        总部
                    </template>
                    <Menu :active-name="act" :open-names="openList" :theme="theme" @on-open-change="changeTest1" @on-select="updateList">
                    <Submenu :name="item.code" v-for="item in dataList" :key="item">
                        <template #title>
                            <Icon type="ios-paper" />
                            {{ item.codeName }}
                        </template>
                        <MenuItem :name="item.code" v-for="item in province_list" :key="item">{{ item.name }}</MenuItem>
                    
                    </Submenu>
                </Menu>
                </Submenu>
            </Menu> -->
            <Card class="table_container" ref="table_container" title="分销切换" style="margin-top: 20px; margin-right: 20px">
                <div style="position: absolute; left: 239px; top: 13px">{{ yyType }}</div>
                <Collapse v-model="value1" style="width: 280px; margin-top: 30px" @on-change="getTest">
                    <Panel name="999">
                        <span :style="{ color: active ? '#57a3f3' : 'black' }">总部</span>
                        <span style="position: absolute; left: 240px">({{ dataList1.length }})</span>
                        <template #content>
                            <div v-for="(item, index) in dataList1" :key="item.id">
                                <Collapse accordion v-model="value2" @on-change="getTest1(item.id, item.below_count, index)" :simple="true">
                                    <Panel :name="item.id">
                                        <span :style="{ color: activeIndex === index ? '#57a3f3' : 'black' }">{{ item.codeName }}</span>
                                        <span style="position: absolute; left: 220px">({{ item.below_count }})</span>
                                        <template #content>
                                            <div v-for="(itemName, zindex) in dataList2" :key="itemName.id">
                                                <!-- <Collapse accordion v-model="value3" @on-change="getTest2(itemName.id)" :simple="true">
                                                    <Panel :name="itemName.id">
                                                        {{ itemName.name }}
                                                    </Panel>
                                                </Collapse> -->
                                                <div @click="getTest2(itemName.id, zindex)" class="personName" :style="{ color: activeIndex1 === zindex ? '#57a3f3' : 'black' }">{{ itemName.name }}</div>
                                            </div>
                                        </template>
                                    </Panel>
                                </Collapse>
                            </div>
                        </template>
                    </Panel>
                </Collapse>
            </Card>
        </div>
        <div style="width: 80vw">
            <searchBody>
                <!-- <TreeSelect v-model="value" :data="treedata" v-width="500"></TreeSelect> -->
                <div style="display: flex;margin: 20px 0;padding: 0;width: 100%;border: 1px solid #dcdee2;
    border-color: #e8eaec;
    flex-wrap: wrap;
    gap: 10px 0;">
                    <div class="form_item">
                        <div class="form_label" style="width: 100px;">手机号码</div>
                        <Input placeholder="请输入手机号码" style="width: 200px;" clearable v-model="params.phone" />
                    </div>
                    <div class="form_item" >
                        <div class="form_label" style="width: 100px;">姓名</div>
                        <Input  placeholder="请输入姓名" style="width: 200px;" clearable v-model="params.name" />
                    </div>
                    <div class="form_item" >
                        <div class="form_label " style="width: 100px;">状态</div>
                        <Select  style="width: 200px;" v-model="params.status" >
                            <Option v-for="info in statusList" :key="info.value" :value="info.value">{{ info.label }}</Option>
                        </Select>
                    </div>
                    <Button type="primary" @click="onSearch"  style="margin-left: 20px; margin-top: 10px">搜索</Button>
                    <Button type="info" @click="reset" style="margin-left: 20px; margin-top: 10px">重置</Button>
                </div>

                <!-- <search class="searchbar" :show-create="false" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset" @create="onEdit()"></search> -->
                <!-- <Button class="addbtn"  type="success" @click="onCreate">新增</Button> -->
                <div style="height: 50px">
                    <Button class="addbtn" v-if="yyType == '省代理'" type="success" @click="onCreate">新增省代理</Button>
                    <Button class="addbtn" v-if="yyType == '经销商'" type="success" @click="onCreate">新增经销商</Button>
                    <Button class="addbtn" v-if="yyType == '小B端'" type="success" @click="onCreate">新增小B端/个人</Button>
                    <!-- <div style="margin-left: 242px;margin-top: 7px;
    color: #2d8cf0;">下级共{{ dataList.length }}人</div> -->
                </div>
                <Card class="table_container" ref="table_container">
                    <Table :key="tableKey" stripe class="table mt_10" style="width: 100%" :columns="dataColumn" :data="dataList">
                        <template slot-scope="{ row }" slot="status">
                            <span :class="'now_state_' + row.status">{{ row.status == 1 ? '代理中' : '已暂停' }}</span>
                        </template>
                        <template slot-scope="{ row }" slot="type">
                            <span v-if="row.type == 1">社会合作伙伴</span>
                            <span v-if="row.type == 5">公司员工</span>
                            <span v-if="row.type == 2">经销商</span>
                            <span v-if="row.type == 3">小B端</span>
                            <span v-if="row.type == 4">个人</span>
                        </template>
                        <!-- <template slot-scope="{ row }" slot="province_code">
                    <span>{{ row.province_code}}</span>
                </template> -->
                        <template slot-scope="{ row }" slot="manager">
                            <div class="table_manager flex flex_wrap align_center">
                                <Button type="success" size="small" ghost @click="onView(row)">详情</Button>
                                <Button type="primary" size="small" ghost @click="onEdit(row)">编辑</Button>
                                <!-- <Button type="primary" size="small" v-if="row.status == 1" ghost @click="showpassword(row)">修改密码</Button> -->
                                <Button type="primary" v-if="row.status == 1" size="small" ghost @click="onPause(row)">暂停代理</Button>
                                <Button type="primary" v-if="row.status == 0" size="small" ghost @click="onPause1(row)">恢复代理</Button>

                                <Button type="primary" v-else size="small" ghost @click="onDelete(row)">删除</Button>
                            </div>
                        </template>
                    </Table>
                </Card>
                <div class="relative width_100_percent text_align_right">
                    <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
                </div>
                <Modal v-model="modal" title="删除" :loading="loading" @on-ok="asyncOK">
                    <p>您是否确认删除此项？</p>
                </Modal>
                <Modal :closable="false" v-model="showCreate" :title="title" width="500" :loading="ajaxLoading">
                    <div class="form_item">
                        <div class="form_label must_input">新密码</div>
                        <Input v-model.trim="formData.new_password" :maxlength="16" show-word-limit></Input>
                    </div>
                    <div class="form_item">
                        <div class="form_label must_input">确认密码</div>
                        <Input v-model.trim="formData.con_password" :maxlength="16" show-word-limit></Input>
                    </div>
                    <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                        <Button type="primary" ghost @click="onCancelCreate">取消</Button>
                        <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmCreate">确定</Button>
                    </div>
                </Modal>
                <Modal v-model="ztmodal" title="暂停代理" :loading="loading" @on-ok="surePause">
                    <p>您是否确认暂停{{ ztname }}的代理？</p>
                </Modal>
                <Modal v-model="ztmodal1" title="暂停代理" :loading="loading" @on-ok="surePause1">
                    <p>您是否确认恢复{{ ztname }}的代理？</p>
                </Modal>
                <Modal :closable="false" v-model="showCreate" :title="title" width="500" :loading="ajaxLoading">
                    <div class="form_item">
                        <div class="form_label must_input">新密码</div>
                        <Input v-model.trim="formData.new_password" :maxlength="16" show-word-limit></Input>
                    </div>
                    <div class="form_item">
                        <div class="form_label must_input">确认密码</div>
                        <Input v-model.trim="formData.con_password" :maxlength="16" show-word-limit></Input>
                    </div>
                    <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                        <Button type="primary" ghost @click="onCancelCreate">取消</Button>
                        <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmCreate">确定</Button>
                    </div>
                </Modal>
                <mySpin :loading="ajaxLoading"></mySpin>
            </searchBody>
        </div>
    </div>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqMerchant } from '@/lib/request/auth2';
import { getAgentList, modPasswode, deleteItem, cancel, updateAgent } from '@/lib/request/auth';
import { consoleJson, isNotNullObject } from '@/lib/util';
import { getCityTitles } from '@/lib/city.data';

export default {
    name: 'serviceProviderList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
    },
    data() {
        return {
            statusList:[
                {
                    value:0,
                    label:'已暂停'
                },
                {
                    value:1,
                    label:'代理中'
                }
            ],
            activeIndex: null,
            activeIndex1: null,
            personColor: '#333',
            color: '#333',
            totalPageNum: 0,
            value1: '999',
            value3: '',
            value2: '',
            act: '1',
            dataList1: [],
            openList: ['1'],
            province_list: [
                {
                    name: '北京市',
                    code: '110000',
                },
                {
                    name: '天津市',
                    code: '120000',
                },
                {
                    name: '河北省',
                    code: '130000',
                },
                {
                    name: '山西省',
                    code: '140000',
                },
                {
                    name: '内蒙古自治区',
                    code: '150000',
                },
                {
                    name: '辽宁省',
                    code: '210000',
                },
                {
                    name: '吉林省',
                    code: '220000',
                },
                {
                    name: '黑龙江省',
                    code: '230000',
                },
                {
                    name: '上海市',
                    code: '310000',
                },
                {
                    name: '江苏省',
                    code: '320000',
                },
                {
                    name: '浙江省',
                    code: '330000',
                },
                {
                    name: '安徽省',
                    code: '340000',
                },
                {
                    name: '福建省',
                    code: '350000',
                },
                {
                    name: '江西省',
                    code: '360000',
                },
                {
                    name: '山东省',
                    code: '370000',
                },
                {
                    name: '河南省',
                    code: '410000',
                },
                {
                    name: '湖北省',
                    code: '420000',
                },
                {
                    name: '湖南省',
                    code: '430000',
                },
                {
                    name: '广东省',
                    code: '440000',
                },
                {
                    name: '广西壮族自治区',
                    code: '450000',
                },
                {
                    name: '海南省',
                    code: '460000',
                },
                {
                    name: '重庆市',
                    code: '500000',
                },
                {
                    name: '四川省',
                    code: '510000',
                },
                {
                    name: '贵州省',
                    code: '520000',
                },
                {
                    name: '云南省',
                    code: '530000',
                },
                {
                    name: '西藏自治区',
                    code: '540000',
                },
                {
                    name: '陕西省',
                    code: '610000',
                },
                {
                    name: '甘肃省',
                    code: '620000',
                },
                {
                    name: '青海省',
                    code: '630000',
                },
                {
                    name: '宁夏回族自治区',
                    code: '640000',
                },
                {
                    name: '新疆维吾尔自治区',
                    code: '650000',
                },
                {
                    name: '台湾省',
                    code: '710000',
                },
                {
                    name: '香港特别行政区',
                    code: '810000',
                },
                {
                    name: '澳门特别行政区',
                    code: '820000',
                },
            ],
            value: '1',
            treedata: [
                {
                    title: 'parent1',
                    expand: true,
                    value: 'parent1',
                    selected: false,
                    checked: false,
                    children: [
                        {
                            title: 'parent 1-1',
                            expand: true,
                            value: 'parent1-1',
                            selected: false,
                            checked: false,
                            children: [
                                {
                                    title: 'leaf 1-1-1',
                                    value: 'leaf1',
                                    selected: false,
                                    checked: false,
                                },
                                {
                                    title: 'leaf 1-1-2',
                                    value: 'leaf2',
                                    selected: false,
                                    checked: false,
                                },
                            ],
                        },
                        {
                            title: 'parent 1-2',
                            expand: true,
                            value: 'parent1-2',
                            selected: false,
                            checked: false,
                            children: [
                                {
                                    title: 'leaf 1-2-1',
                                    value: 'leaf3',
                                    selected: false,
                                    checked: false,
                                },
                                {
                                    title: 'leaf 1-2-1',
                                    value: 'leaf4',
                                    selected: false,
                                    checked: false,
                                },
                            ],
                        },
                    ],
                },
            ],
            ztname: '',
            ztid: '',
            ztmodal: false,
            deleteid: '',
            loading: false,
            modal: false,
            formData: {
                id: '',
            },
            title: '',
            showCreate: false,
            searchs: [
                { id: hm.createId(), label: '身份ID', placeholder: '请输入身份ID', type: 'input', bind: 'id_card_no' },
                { id: hm.createId(), label: '姓名', placeholder: '请输入姓名', type: 'input', bind: 'name' },
                {
                    id: hm.createId(),
                    label: '状态',
                    placeholder: '选择状态',
                    type: 'select',
                    bind: 'status',
                    list: [
                        { id: '1', title: '代理中' },
                        { id: '0', title: '已暂停' },
                    ],
                },
            ],
            // searchs: [{ id: hm.createId(), label: '关键词', placeholder: '输入关键词', type: 'input', bind: 'name' }],
            tableKey: 'tableKey',
            dataColumn: [
                { key: 'id', title: '编号' },
                { key: 'name', title: '姓名' },
                { key: 'id_card_no', title: '身份ID' },
                { key: 'phone', title: '联系方式' },
                // { key: 'receiveNum', title: '接单情况（次）' },
                { key: 'codeName', title: '代理省份' },
                { slot: 'type', title: '身份角色' },
                { key: 'created_at', title: '入驻时间' },
                { slot: 'status', title: '状态' },
                { slot: 'manager', title: '操作' },
            ],
            query: {
                page: 1,
                limit: 10,
                pid: 0,
            },
            dataList2: [],
            dataList3: [],
            dataList: [],
            theme: 'light',
            yyType: '省代理',
            lengthArr: [],
            active: false,
            ztmodal1: false,
        };
    },
    mounted() {
        // this.params.pid = 0;

        this.getList1();
        this.getList2();
        setTimeout(() => {
            this.getList();
            // this.getLength();
        }, 100);
    },
    methods: {
        reset(){
            this.params.name = undefined;
            this.params.phone = undefined;
            this.params.status = undefined;

            this.getListfy();
        },
        onSearch() {
            this.getListfy();
            console.log('231');
        },
        currentChange(page) {
            console.log('777');
            this.params.pageNums = page;
            this.getListfy();
        },
        getLength() {
            this.params.pid = 0;

            this.showAjaxLoading();
            getAgentList(this.params)
                .then(res => {
                    // this.tableKey = hm.createId();
                    // this.dataInjectDataList(res);
                    this.dataList = res.data;
                    this.dataList1 = res.data;
                    this.dataList1.forEach(item => {
                        // this.getTest1(item.id);
                        this.params.pid = Number(item.id);
                        this.params.types = item.below_count;
                        this.showAjaxLoading();
                        this.dataList1 = [];
                        getAgentList(this.params)
                            .then(res1 => {
                                this.dataList2 = res1.data.list;
                                console.log(res1.data.length, 'res1.data.length');
                                var obj = {};
                                // obj.len = res1.data.length;
                                // this.lengthArr.push(obj);
                                // this.dataList2.forEach((item1=>{
                                //     item1.len = res1.data.length;
                                //     // var obj = {};
                                //     // obj.len = res1.data.length;
                                //     // item.len = obj.len;
                                // }))
                                // this.dataList1 = this.dataList1.concat(this.lengthArr)
                                console.log(this.dataList1, '/////4//**');
                            })
                            .finally(() => {
                                this.hideAjaxLoading();
                            });
                    });

                    // this.getCodeName();
                    // this.dataList.forEach(item => {
                    //     item.codeName = getCityTitles([Number(item.province_code)])[0];
                    // });
                    // console.log(this.dataList, 'this.dataList');
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        getTest3() {
            this.getList4();
        },
        getTest1(id, types, index) {
            this.params.status = undefined;
            this.params.name = undefined;
            this.params.phone = undefined;
            this.active = false;
            this.activeIndex = index;
            this.activeIndex1 = null;
            this.params.types = types;
            this.params.pid = id;
            this.yyType = '经销商';
            // this.getList();
            this.getList1(id, types);
        },
        getTest2(id, index) {
            this.params.status = undefined;
            this.params.name = undefined;
            this.params.phone = undefined;
            this.active = false;
            this.activeIndex1 = index;
            this.activeIndex = null;
            this.params.pid = id;
            this.yyType = '小B端';
            this.getList2(id);
            // this.getList2();
        },
        getList1(id, types) {
            this.params.pid = Number(id);
            this.params.types = types;
            this.showAjaxLoading();
            getAgentList(this.params)
                .then(res => {
                    this.tableKey = hm.createId();
                    this.dataInjectDataList(res);
                    this.dataList = res.data.list;
                    // this.dataList2 = res.data.list;
                    this.getCodeName();
                    console.log(this.dataList, 'this.dataList');
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
                var needData = {};
                needData.pid = Number(id);
                needData.types = types;
                needData.pageNums = undefined;
                needData.pageSize = undefined;
                getAgentList(needData)
                .then(res => {
                    this.tableKey = hm.createId();
                    this.dataInjectDataList(res);
                    // this.dataList = res.data.list;
                    this.dataList2 = res.data.list;
                    this.getCodeName();
                    this.dataList2.forEach(item => {
                        if(item.province_code == '1' ){
                            item.codeName = '琪朗总部'
                        }else{
                            item.codeName = getCityTitles([Number(item.province_code)])[0];
                        }
                        

                    });
                    console.log(this.dataList, 'this.dataList');
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        getList2(id) {
            this.params.pid = Number(id);
            this.showAjaxLoading();
            getAgentList(this.params)
                .then(res => {
                    this.tableKey = hm.createId();
                    this.dataInjectDataList(res);
                    this.dataList = res.data.list;
                    // this.dataList3 = res.data.list;
                    this.getCodeName();
                    console.log(this.dataList, 'this.dataList');
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
                var needData = {};
                needData.pid = Number(id);
                needData.types = this.params.types;
                // needData.pageNums =  this.params.pageNums;
                // needData.pageSize = this.params.pageSize;
                needData.pageNums =  undefined;
                needData.pageSize = undefined;
                getAgentList(needData)
                .then(res => {
                    this.tableKey = hm.createId();
                    this.dataInjectDataList(res);
                    // this.dataList = res.data.list;
                    this.dataList3 = res.data.list;
                    this.getCodeName();
                    this.dataList3.forEach(item => {
                        if(item.province_code == '1' ){
                            item.codeName = '琪朗总部'
                        }else{
                            item.codeName = getCityTitles([Number(item.province_code)])[0];
                        }
                    });
                    console.log(this.dataList, 'this.dataList');
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        getList22() {
            this.params.pid = 2;
            this.showAjaxLoading();
            getAgentList(this.params)
                .then(res => {
                    this.tableKey = hm.createId();
                    this.dataInjectDataList(res);
                    this.dataList = res.data.list;
                    this.dataList2 = res.data.list;
                    this.getCodeName();
                    this.dataList2.forEach(item => {
                        if(item.province_code == '1' ){
                            item.codeName = '琪朗总部'
                        }else{
                            item.codeName = getCityTitles([Number(item.province_code)])[0];
                        }
                    });
                    console.log(this.dataList, 'this.dataList');
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        getList4() {
            this.params.pid = 2;
            this.showAjaxLoading();
            getAgentList(this.params)
                .then(res => {
                    this.tableKey = hm.createId();
                    this.dataInjectDataList(res);
                    this.dataList = res.data.list;
                    this.getCodeName();
                    this.dataList.forEach(item => {
                        if(item.province_code == '1' ){
                            item.codeName = '琪朗总部'
                        }else{
                            item.codeName = getCityTitles([Number(item.province_code)])[0];
                        }
                    });
                    console.log(this.dataList, 'this.dataList');
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        getList3() {
            this.showAjaxLoading();
            getAgentList({
                pageNums: 1,
                pageSize: 10,
                pid: 2,
            })
                .then(res => {
                    // this.tableKey = hm.createId();
                    // this.dataInjectDataList(res);
                    // this.dataList = res.data;
                    this.dataList3 = res.data.list;
                    this.getCodeName();
                    this.dataList3.forEach(item => {
                        if(item.province_code == '1' ){
                            item.codeName = '琪朗总部'
                        }else{
                            item.codeName = getCityTitles([Number(item.province_code)])[0];
                        }
                    });
                    console.log(this.dataList, 'this.dataList');
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        getTest() {
            this.params.status = undefined;
            this.params.name = undefined;
            this.params.phone = undefined;
            this.activeIndex = null;
            this.activeIndex1 = null;
            this.active = true;
            this.params.pid = 0;
            this.yyType = '省代理';
            this.getList();
        },
        changeTest1() {
            this.active = false;
            this.params.pid = 0;
            this.getList();
        },
        changeTest(name) {
            // this.openList = ['1']
            console.log(name, 'namesss');
            this.params.pid = 0;
            this.getList();
        },
        toZb() {
            console.log('666');
        },
        updateList(name) {
            this.openList = ['1'];
            console.log(name, '9999999999');
            this.act = name;
            this.params.pid = 2;
            this.getList();
        },
        getCodeName() {
            console.log(getCityTitles([12e4]), '12e4');
        },
        surePause() {
            cancel({ id: this.ztid }).then(res => {
                this.fadeAlert('操作成功');
                this.ztmodal = false;
                this.getList();
            });
        },
        surePause1() {
            updateAgent({ id: this.ztid, status: 1 }).then(res => {
                this.fadeAlert('操作成功');
                this.ztmodal1 = false;
                this.getList();
            });
        },
        onPause(row) {
            this.ztid = row.id;
            this.ztname = row.name;
            this.ztmodal = true;
        },
        onPause1(row) {
            this.ztid = row.id;
            this.ztname = row.name;
            this.ztmodal1 = true;
        },
        onDelete(row) {
            this.modal = true;
            this.deleteid = row.id;
        },
        asyncOK() {
            deleteItem({ id: this.deleteid }).then(res => {
                this.fadeAlert('修改成功');
                this.modal = false;
                this.getList();
            });
        },
        onConfirmCreate() {
            this.showAjaxLoading();
            modPasswode(this.formData)
                .then(res => {
                    this.showCreate = false;
                    this.fadeAlert('修改成功');
                    this.formData = {};
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        showpassword(row) {
            this.formData.id = row.id;
            this.title = '修改密码';
            this.showCreate = true;
        },
        onCancelCreate() {
            this.showCreate = false;
        },
        //获取列表数据
        getList() {
            this.params.types = 'count';
            this.params.pid = 0;
            this.showAjaxLoading();
            getAgentList(this.params)
                .then(res => {
                    this.tableKey = hm.createId();
                    this.dataInjectDataList(res);
                    this.dataList = res.data.list;
                    // this.dataList1 = res.data.list;
                    this.getCodeName();
                    this.dataList.forEach(item => {
                        if(item.province_code == '1' ){
                            item.codeName = '琪朗总部'
                        }else{
                            item.codeName = getCityTitles([Number(item.province_code)])[0];
                        }
                    });
                    console.log(this.dataList, 'this.dataList');
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
                var needData = {};
                needData.types = 'count';
                needData.pid = 0;
                needData.pageSize = undefined;
                needData.pageNums = undefined;
                getAgentList(needData)
                .then(res => {
                    this.tableKey = hm.createId();
                    this.dataInjectDataList(res);
                    // this.dataList = res.data.list;
                    this.dataList1 = res.data.list;
                    this.getCodeName();
                    res.data.list.forEach(item => {
                        if(item.province_code == '1' ){
                            item.codeName = '琪朗总部'
                        }else{
                            item.codeName = getCityTitles([Number(item.province_code)])[0];
                        }
                    });
                    console.log(this.dataList, 'this.dataList');
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        getListfy() {
            this.params.types = 'count';
            // this.params.pid = 0;
            this.showAjaxLoading();
            getAgentList(this.params)
                .then(res => {
                    this.tableKey = hm.createId();
                    this.dataInjectDataList(res);
                    this.dataList = res.data.list;
                    // this.dataList1 = res.data.list;
                    this.getCodeName();
                    this.dataList.forEach(item => {
                        if(item.province_code == '1' ){
                            item.codeName = '琪朗总部'
                        }else{
                            item.codeName = getCityTitles([Number(item.province_code)])[0];
                        }
                    });
                    console.log(this.dataList, 'this.dataList');
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //新建/编辑
        onEdit(_info = {}) {
            let info = window.structuredClone(_info);
            if (isNotNullObject(info)) {
                info.userTel = info.userPhone;
                info.address_code = !!info.address_code ? info.address_code.split(',') : [];
                info.address_note = !!info.address ? info.address.split(';')[1] : null;
                delete info.userPhone;
            } else {
                info.create = 1;
            }
            // this.goName('distributionListAdd', info);
            this.$router.push({ path: '/distributionListAdd', query: { isEdit: true, id: info.id, pid: this.params.pid, yyType: this.yyType } });
        },
        onCreate() {
            this.$router.push({ path: '/distributionListAdd', query: { isEdit: false, isDetail: false, pid: this.params.pid, yyType: this.yyType } });
            // this.goName('distributionListAdd');
        },

        //详情
        onView(info = {}) {
            // let { id } = info || {};
            // this.goUrl('/serviceProviderView', { id });
            this.$router.push({ path: '/distributionListAdd', query: { isEdit: true, id: info.id, isDetail: true, isProxy: 1, pid: this.params.pid, yyType: this.yyType } });
        },
    },
};
</script>
<style lang="less" scoped>
.table_container {
    & .ivu-switch span {
        font-size: revert;
    }
}
.personName {
    margin: 15px 33px;
    color: #333;
    cursor: pointer;
}
.addbtn {
    // position: absolute;
    // top: 109px;
    // left: 35px;
    margin-left: 24px;
}
</style>
